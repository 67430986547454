<template>
  <div>
    <datatable
      :columns="headers"
      :total="cashbills.meta.page.total"
      :perPage="cashbills.meta.page.perPage"
      :currentPage="cashbills.meta.page.currentPage"
      @pagechanged="onPageChanged"
    >
      <template v-slot:tbody="{ classes }">
        <tr
          v-for="(cashbill, index) in cashbills.data"
          :key="cashbill.id"
          :class="[
            classes.tr,
            index % 2 === 0 ? 'bg-white' : 'bg-gray-50',
            'cursor-pointer bg-white hover:bg-green-100',
          ]"
          @click="onDetail(cashbill)"
        >
          <td :class="[classes.td]" v-if="columns.includes('code')">
            <span class="font-bold text-gray-900">{{
              cashbill.attributes.code
            }}</span>
          </td>
          <td :class="classes.td" v-if="columns.includes('date')">
            {{ cashbill.attributes.date | formatDate('ll') }}
          </td>
          <td :class="classes.td" v-if="columns.includes('destination_office_code')">
            {{ cashbill.attributes.destination_office_code }}
          </td>
          <td :class="classes.td" v-if="columns.includes('period')">
            {{ cashbill.attributes.period }}
          </td>
          <td :class="[classes.td, 'text-right']" v-if="columns.includes('total_pv')">
            {{ cashbill.attributes.total_pv | toCurrency }}
          </td>
          <td :class="[classes.td, 'text-right']" v-if="columns.includes('total_bv')">
            {{ cashbill.attributes.total_bv | toCurrency }}
          </td>
          <td :class="[classes.td, 'text-right']" v-if="columns.includes('total_member_price')">
            {{ cashbill.attributes.total_member_price | toCurrency }}
          </td>
          <td :class="[classes.td, 'text-right']" v-if="columns.includes('total_consumer_price')">
            {{ cashbill.attributes.total_consumer_price | toCurrency }}
          </td>
        </tr>
      </template>
    </datatable>
    <cashbill-nuera-detail-modal :visible="detailModal.visible" :cashbillId="detailModal.cashbillId" @close="detailModal.visible = false" />
    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex'
import CashbillNueraDetailModal from './cashbill-nuera-detail-modal.vue'

export default {
  mixins: [requestMixin],
  components: {
    CashbillNueraDetailModal
  },
  props: {
    columns: Array
  },
  data() {
    return {
      cashbills: {
        data: [],
        meta: {
          page: {
            total: 0,
            perPage: 10,
            currentPage: 1,
          },
        },
      },
      loading: false,
      detailModal: {
        visible: false,
        cashbillId: null
      }
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    headers() {
      return [
        { id: 'code', name: 'Nomor Cashbill' },
        { id: 'date', name: 'Tanggal' },
        { id: 'destination_office_code', name: 'Kode Mitra Usaha' },
        { id: 'period', name: 'Periode' },
        { id: 'total_pv', name: 'PV', theadClass: 'text-right' },
        { id: 'total_bv', name: 'BV', theadClass: 'text-right' },
        { id: 'total_member_price', name: 'Total Harga Mitra Usaha', theadClass: 'text-right' },
        { id: 'total_consumer_price', name: 'Total Harga Konsumen', theadClass: 'text-right' },
      ].filter(header => this.columns.includes(header.id));
    },
  },
  methods: {
    onPageChanged(page) {
      this.loadCashbills({
        'page[number]': page
      })
    },
    onDetail(cashbill) {
      this.detailModal.cashbillId = cashbill.id
      this.detailModal.visible = true
    },
    async loadCashbills(params = {}) {
      this.loading = true;

      const [res, error] = await this.request('/api/v1/cashbills', {
        params: {
          'page[size]': 5,
          'filter[destination_office_code]': this.me.office_code,
          'sort': '-createdAt',
          ...params
        },
      });

      if (!error) {
        this.cashbills = res;
      }

      this.loading = false;
    },
  },
  activated() {
    this.loadCashbills();
  },
};
</script>

<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="my-6 rounded-lg bg-white p-4">
      <div class="border-b border-gray-200 py-5 sm:px-6">
        <div class="flex flex-wrap items-center justify-between sm:flex-nowrap">
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Penerimaan Barang dari Stockist
          </h3>
        </div>
      </div>

      <div class="mb-5 rounded-lg bg-white py-4">
        <div class="flex flex-col">
          <div class="flex items-center justify-end space-x-2">
            <div class="relative mt-1 rounded-md shadow-sm">
              <div>
                <select
                  v-model="is_completed"
                  @change="filterByCompleted"
                  class="focus:outline-none mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green-500 focus:ring-green-500 sm:text-sm"
                >
                  <option :value="null">Semua</option>
                  <option :value="true">Diterima</option>
                  <option :value="false">Belum diterima</option>
                </select>
              </div>
            </div>
            <base-search
              v-model="search"
              @input="debounceSearch"
              placeholder="Cari kode atau nama"
            />
          </div>
          <div class="my-2 flex sm:-mx-6 lg:-mx-8">
            <div
              class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
            >
              <Datatable
                :total="getOrders.meta.page.total"
                :perPage="getOrders.meta.page.perPage"
                :currentPage="getOrders.meta.page.currentPage"
                @pagechanged="onPageChangeOrder"
              >
                <template v-slot:thead>
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Nomor Pembelian
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Kode Stockist
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Tanggal Pembelian
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Total Produk
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Total Berat
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Total Harga
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        STATUS
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:tbody>
                  <tbody>
                    <tr
                      class="cursor-pointer bg-white hover:bg-green-100"
                      v-for="(data, dataIdx) in getOrders.data"
                      :key="data.id"
                      :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                      @click.prevent="tableDetails(data)"
                    >
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      >
                        {{ data.attributes.destination_code }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        {{ data.attributes.origin_office_code }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        {{ dayjs(data.attributes.updatedAt).format('ll LT') }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                      >
                        {{ data.attributes.item_count }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                      >
                        {{ data.attributes.grand_total_weight }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                      >
                        {{ data.attributes.final_price }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                      >
                        <span
                          v-if="data.attributes.is_completed"
                          class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                        >
                          Diterima
                        </span>
                        <span
                          v-else
                          class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
                        >
                          Belum diterima
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </Datatable>
            </div>
          </div>
          <transition
            name="customFade"
            enter-active-class="fadeIn"
            leave-active-class="fadeOut"
          >
            <div
              v-if="openDetail"
              style="animation-duration: 0.3s"
              class="fixed inset-0 z-10 overflow-y-auto"
              aria-labelledby="modal-title"
              role="dialog"
              aria-modal="true"
            >
              <div
                class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
              >
                <div
                  class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                  aria-hidden="true"
                ></div>
                <span
                  class="hidden sm:inline-block sm:h-screen sm:align-middle"
                  aria-hidden="true"
                  >&#8203;</span
                >
                <div
                  class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
                >
                  <div class="mb-8 flex justify-between">
                    <div>
                      <h3 class="text-lg font-bold leading-6 text-gray-900">
                        Detail Order Barang
                      </h3>
                      <p class="mt-1 max-w-2xl text-sm text-gray-500">
                        Data Order Barang
                      </p>
                    </div>
                    <div>
                      <img
                        src="@/assets/images/logo.png"
                        alt=""
                        class="flex justify-end"
                      />
                    </div>
                  </div>
                  <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl class="flex flex-row gap-x-4">
                      <div class="w-full">
                        <dt class="text-sm font-bold text-gray-700">No PO</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{ detailBrgMasuk.attributes.code }}
                        </dd>
                      </div>
                      <div class="w-full">
                        <dt class="text-sm font-bold text-gray-700">Tanggal</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{
                            dayjs(detailBrgMasuk.attributes.updatedAt).format(
                              'll LT'
                            )
                          }}
                        </dd>
                      </div>
                      <div class="w-full">
                        <dt class="text-sm font-bold text-gray-700">
                          Kode Stockist
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{
                            detailBrgMasuk.relationships['origin-office'].data
                              ? getRelationships(
                                  detailBrgMasuk.relationships['origin-office']
                                    .data.id
                                ).attributes.code
                              : '-'
                          }}
                        </dd>
                      </div>
                    </dl>
                    <dl class="mt-6 flex flex-row gap-x-4">
                      <div class="w-full">
                        <dt class="text-sm font-bold text-gray-700">
                          Nama Stockist
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{
                            detailBrgMasuk.relationships['origin-office'].data
                              ? getRelationships(
                                  detailBrgMasuk.relationships['origin-office']
                                    .data.id
                                ).attributes.name
                              : '-'
                          }}
                        </dd>
                      </div>
                      <div class="w-full">
                        <dt class="text-sm font-bold text-gray-700">
                          Alamat Stockist 1
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{
                            detailBrgMasuk.relationships['origin-office'].data
                              ? getRelationships(
                                  detailBrgMasuk.relationships['origin-office']
                                    .data.id
                                ).attributes.address
                              : '-'
                          }}
                        </dd>
                      </div>
                      <div class="w-full">
                        <dt class="text-sm font-bold text-gray-700">
                          Alamat Stockist 2
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{
                            detailBrgMasuk.relationships['origin-office'].data
                              ? getRelationships(
                                  detailBrgMasuk.relationships['origin-office']
                                    .data.id
                                ).attributes.address2
                              : '-'
                          }}
                        </dd>
                      </div>
                    </dl>
                    <div class="my-6 sm:col-span-6">
                      <dt class="text-sm font-bold text-gray-700">
                        Daftar Barang
                      </dt>
                      <Datatable
                        :paginated="false"
                        :footer="false"
                        class="mt-4"
                      >
                        <template v-slot:thead>
                          <thead class="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                KODE BARANG
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                JUMLAH
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                BELUM DITERIMA
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                BERAT
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                TOTAL HARGA
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                TOTAL BERAT
                              </th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:tbody>
                          <tbody>
                            <tr
                              class="bg-white"
                              v-for="(barang, index) in detailBrgMasuk
                                .attributes.order_details"
                              :key="index"
                            >
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                              >
                                {{ barang.product_code }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                              >
                                {{ barang.product_qty | toCurrency }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                              >
                                {{ barang.product_not_recieved | toCurrency }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                              >
                                {{ barang.product_weight | toCurrency }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                              >
                                {{ barang.total_price | toCurrency }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                              >
                                {{ barang.total_weight | toCurrency }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </Datatable>
                    </div>
                    <div class="flex">
                      <div class="flex w-2/3 flex-col gap-y-2">
                        <span
                          v-if="detailBrgMasuk.attributes.is_completed"
                          class="inline-flex w-24 items-center justify-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                        >
                          Selesai
                        </span>
                        <span
                          v-else
                          class="inline-flex w-36 items-center justify-center rounded-full bg-red-100 px-3 py-1 text-center text-sm font-medium text-red-800"
                        >
                          Belum Selesai
                        </span>
                      </div>
                      <div class="flex w-1/3 flex-col gap-y-2">
                        <div class="inline-flex w-full justify-between">
                          <div class="sm:col-span-1">
                            <label
                              for="invoice"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Total harga keseluruhan
                            </label>
                          </div>
                          <div class="sm:col-span-1">
                            <label
                              for="invoice"
                              class="block text-sm font-bold text-gray-900"
                            >
                              {{ detailBrgMasuk.attributes.final_price }}
                            </label>
                          </div>
                        </div>
                        <div class="inline-flex w-full justify-between">
                          <div class="sm:col-span-1">
                            <label
                              for="invoice"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Total berat keseluruhan
                            </label>
                          </div>
                          <div class="sm:col-span-1">
                            <label
                              for="invoice"
                              class="block text-sm font-bold text-gray-900"
                            >
                              {{ detailBrgMasuk.attributes.grand_total_weight }}
                              Kg
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-end space-x-2">
                    <button
                      @click="validate(detailBrgMasuk)"
                      v-if="
                        detailBrgMasuk.relationships['stock-movements'].data
                          .length > 0
                      "
                      class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="mr-2 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                        />
                      </svg>
                      Validasi
                    </button>
                    <router-link
                      v-else
                      :to="
                        'penerimaan-barang-dari-supplier/' + detailBrgMasuk.id
                      "
                      class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="mr-2 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-2m-4-1v8m0 0l3-3m-3 3L9 8m-5 5h2.586a1 1 0 01.707.293l2.414 2.414a1 1 0 00.707.293h3.172a1 1 0 00.707-.293l2.414-2.414a1 1 0 01.707-.293H20"
                        />
                      </svg>
                      Terima
                    </router-link>
                    <button
                      @click="openDetail = !openDetail"
                      type="button"
                      class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                    >
                      Tutup
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import { mapGetters, mapActions } from 'vuex';
import Datatable from '@/components/base/Datatable';
import dayjs from 'dayjs';
import { StorageService } from '@/services/storage.service';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'Home',
  components: {
    Datatable,
    BaseSearch,
  },

  data() {
    const destination_warehouse_id = StorageService.getUser().current_warehouse;

    return {
      destination_warehouse_id,
      search: null,
      is_completed: false,
      openDetail: false,
      dataBrgMasuk: {},
      detailBrgMasuk: {},
    };
  },

  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
    }),
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
      receiveStockMovement: 'stock_movements/receiveStockMovement',
    }),
    debounceSearch: debounce(function () {
      this.fetchOrders({
        destination_warehouse_id: this.destination_warehouse_id,
        pageNumber: 1,
        pageSize: 5,
        is_completed: this.is_completed,
        is_valid_for_payment: true,
        search: this.search,
        is_shipped: true,
      });
    }, 300),
    onPageChangeOrder(page) {
      this.fetchOrders({
        destination_warehouse_id: this.destination_warehouse_id,
        pageNumber: page,
        pageSize: 5,
        is_completed: this.is_completed,
        is_valid_for_payment: true,
        search: this.search,
        is_shipped: true,
      });
    },
    filterByCompleted() {
      this.fetchOrders({
        destination_warehouse_id: this.destination_warehouse_id,
        pageNumber: 1,
        pageSize: 5,
        is_completed: this.is_completed,
        is_valid_for_payment: true,
        is_shipped: true,
      });
    },
    tableDetails(data) {
      this.openDetail = !this.openDetail;
      this.detailBrgMasuk = data;
    },
    getRelationships(id) {
      let Stockist = this.getOrders.included.filter(function (el) {
        return el.id === id;
      });
      return Stockist[0];
    },
    validate(data) {
      this.receiveStockMovement({
        id: data.relationships['stock-movements'].data[0].id,
      }).then((response) => {
        if (response) {
          this.openDetail = false;
          this.fetchOrders({
            destination_warehouse_id: this.destination_warehouse_id,
            pageNumber: 1,
            pageSize: 5,
            is_completed: this.is_completed,
            is_valid_for_payment: true,
            is_shipped: true,
          });
        }
      });
    },
  },
  created() {
    this.fetchOrders({
      destination_warehouse_id: this.destination_warehouse_id,
      pageNumber: 1,
      pageSize: 5,
      is_completed: this.is_completed,
      is_valid_for_payment: true,
      is_shipped: true,
    });
  },
};
</script>

<template>
  <div class="mx-auto px-4 pb-12 text-xs sm:px-4 lg:px-8">
    <div class="my-6 rounded-lg bg-white p-4">
      <div class="flex w-full justify-end">
        <DownloadButton @click="handleDownload" />
      </div>
      <div class="border-b border-gray-200 py-5 sm:px-6">
        <div>
          <!--- Downline -->
          <!-- Downline Kedalaman-->
          <table>
            <tbody>
              <tr>
                <td>
                  No.Tetap / No.Sementara <br />
                  Nama Mitra Usaha <br />
                  Peringkat
                </td>
                <td>
                  :<br />
                  :<br />
                  :
                </td>
                <td colspan="2">
                  N-1005362 / AE.2398-00120<br />
                  SUCIWINARSIH<br />
                  Manager
                </td>
              </tr>
              <tr>
                <td>Personal Omzet<br /></td>
                <td>:</td>
                <td align="left">0<br /></td>
              </tr>
            </tbody>
          </table>
          <table width="700">
            <tbody>
              <tr>
                <td colspan="3"><b>Rincian Mitra Usaha Langsung</b></td>
              </tr>
              <tr>
                <td><b>No. Tetap</b></td>
                <td><b>Nama Mitra Usaha</b></td>
                <td align="center"><b>Peringkat</b></td>
                <td align="right"><b>PV</b></td>
                <td align="center"><b>Lihat</b></td>
              </tr>
              <tr>
                <td>N-1154385</td>
                <td>SARMI</td>
                <td align="center">D</td>

                <td align="right">0</td>

                <td align="center">
                  <a href="lihat_down.php?id_down=VGkweE1UVTBNemcx">Down</a>
                </td>
              </tr>
              <tr>
                <td>N-1340459</td>
                <td>ELMI FEBRIANI</td>
                <td align="center">D</td>

                <td align="right">0</td>

                <td align="center">
                  <a href="lihat_down.php?id_down=VGkweE16UXdORFU1">Down</a>
                </td>
              </tr>
              <tr>
                <td>N-1401946</td>
                <td>PIPIT UMI SAROPAH</td>
                <td align="center">SM</td>

                <td align="right">0</td>

                <td align="center">
                  <a href="lihat_down.php?id_down=VGkweE5EQXhPVFEy">Down</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DownloadButton from '@/components/button/DownloadButton.vue';

export default {
  name: 'Pajak',
  components: {
    DownloadButton,
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({}),
    handleDownload() {
      window.print();
    },
  },
};
</script>

<template>
  <div style="width: fit-content" class="mx-auto text-xs">
    <div>
      <table>
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td>
              Lembar ke-1 untuk : Wajib Pajak<br />
              Lembar ke-2 untuk : Kantor Pelayanan Pajak<br />
              Lembar ke-3 untuk : Pemotong Pajak
            </td>
          </tr>
          <tr>
            <td><img src="images/logopajak.jpg" /></td>
            <td align="center">
              DEPARTEMEN KEUANGAN REPUBLIK INDONESIA<br />
              DIREKTORAT JENDRAL PAJAK<br />
              KANTOR PELAYANAN PAJAK<br />
              YOGYAKARTA
            </td>
            <td></td>
          </tr>
          <tr>
            <td colspan="3" align="center">
              <b>BUKTI PEMOTONGAN PPH. PASAL 21</b>
            </td>
          </tr>
          <tr>
            <td colspan="3" align="center"><b>NOMOR : </b></td>
          </tr>
        </tbody>
      </table>

      <br />
      <table>
        <tbody>
          <tr>
            <td>NPWP</td>
            <td>:&nbsp;</td>
          </tr>
          <tr>
            <td>Nama Mitra Usaha</td>
            <td>:&nbsp;&nbsp;(&nbsp;N-1000233&nbsp;)</td>
          </tr>
          <tr>
            <td>Alamat</td>
            <td>:&nbsp;&nbsp;&nbsp;</td>
          </tr>
        </tbody>
      </table>
      <br />
      <table border="1">
        <tbody>
          <tr>
            <td align="center">No.</td>
            <td align="center">Jenis Penghasilan</td>
            <td align="center">Jumlah <br />Penghasilan <br />Bruto</td>
            <td align="center">Perkiraan <br />Penghasilan <br />Neto</td>
            <td align="center">Tarif</td>
            <td align="center">PPh yang dipotong</td>
          </tr>
          <tr>
            <td align="center">(1)</td>
            <td align="center">(2)</td>
            <td align="center">(3)</td>
            <td align="center">(4)</td>
            <td align="center">(5)</td>
            <td align="center">(6)</td>
          </tr>
          <tr>
            <td>1.</td>
            <td>
              Upah Harian, Mingguan,<br />Satuan, Borongan, Uang<br />Saku
              Harian
            </td>
            <td align="right">0</td>
            <td></td>
            <td></td>
            <td align="right">0</td>
          </tr>
          <tr>
            <td>2.</td>
            <td>Hadiah atau Penghargaan</td>
            <td align="right">0</td>
            <td></td>
            <td></td>
            <td align="right">0</td>
          </tr>
          <tr>
            <td>3.</td>
            <td>Komisi Petugas Dinas Luar<br />Asuransi</td>
            <td align="right">0</td>
            <td></td>
            <td></td>
            <td align="right">0</td>
          </tr>
          <tr>
            <td>4.</td>
            <td>Komisi Petugas Penjaja<br />Barang Dagangan</td>
            <td align="right">0</td>
            <td></td>
            <td></td>
            <td align="right">0</td>
          </tr>
          <tr>
            <td>5.</td>
            <td>
              Komisi Distributor<br />
              MLM/direct selling
            </td>
            <td align="right">Rp. 0</td>
            <td></td>
            <td></td>
            <td align="right">Rp. 0</td>
          </tr>
          <tr>
            <td>6.</td>
            <td>Penarikan dana pada Dana<br />Pensiun</td>
            <td align="right">0</td>
            <td></td>
            <td></td>
            <td align="right">0</td>
          </tr>
          <tr>
            <td>7.</td>
            <td>Honorarium atau Imbalan<br />lainnya</td>
            <td align="right">0</td>
            <td></td>
            <td></td>
            <td align="right">0</td>
          </tr>
          <tr>
            <td>8.</td>
            <td>
              Honorarium atau Imbalan<br />lainnya yang dibayarkan<br />kepada
              Tenaga Ahli
            </td>
            <td align="right">0</td>
            <td></td>
            <td></td>
            <td align="right">0</td>
          </tr>
          <tr>
            <td colspan="2" align="center"><b>JUMLAH</b></td>
            <td colspan="3"></td>
            <td align="right">Rp. 0</td>
          </tr>
        </tbody>
      </table>
      <br />
      <table>
        <tbody>
          <tr>
            <td border="1">
              Perhatian<br />1. Jumlah Pajak Penghasilan Pasal 21 yang<br />dipotong
              di atas merupakan Angsuran atas<br />
              Pajak Penghasilan yang terutang untuk<br />tahun pajak yang
              bersangkutan. Simpanlah<br />bukti pemotongan ini baik-baik
              untuk<br />diperhitungkan sebagai kredit pajak dalam<br />Surat
              pemberitahuan (SPT) Tahunan PPh<br />Orang Pribadi.<br />2. Bukti
              Pemotongan ini dianggap sah apabila<br />diisi dengan lengkap dan
              benar.
            </td>
            <td></td>
            <td></td>
            <td>
              <center>Yogyakarta, 20 JULI&nbsp;2023</center>
              <br />
              <center><b>Pemotong Pajak</b></center>
              <br />
              <table>
                <tbody>
                  <tr>
                    <td>NPWP</td>
                    <td>:</td>
                    <td>02-264.651.7.542.000</td>
                  </tr>
                  <tr>
                    <td>Nama</td>
                    <td>:</td>
                    <td>PT. NATURAL NUSANTARA</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td align="center">
                      <br /><br /><br />Ir. Abdullah<br />NPWP =
                      24-062.482.5.542.000
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <b>F.1.1.33.01<b>&nbsp;&nbsp;&nbsp;&nbsp;</b></b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <br />
  </div>
</template>
<script>
export default {
  name: 'PajakTable',
};
</script>

<template>
  <div style="width: fit-content" class="mx-auto text-xs">
    <strong>PERINCIAN PEMOTONGAN PPH. PASAL 21</strong><br />
    --------------------------------------------------------------------<br />
    Bonus Periode : <b>Juni&nbsp;2023</b><br /><br />
    <table>
      <tbody>
        <tr>
          <td>Nama Mitra Usaha</td>
          <td>:&nbsp;&nbsp;(&nbsp;N-1000233&nbsp;)</td>
        </tr>
        <tr>
          <td>NPWP</td>
          <td>:&nbsp;</td>
        </tr>
      </tbody>
    </table>
    <br />
    <table>
      <tbody>
        <tr>
          <td>Jumlah Penghasilan Mitra Usaha</td>
          <td>: Rp.</td>
          <td align="right">0</td>
        </tr>
        <tr>
          <td>Jumlah Penghasilan Stockist</td>
          <td>: Rp.</td>
          <td align="right">0</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td align="right">-----------------</td>
          <td>(+)</td>
        </tr>
        <tr>
          <td>Total Penghasilan</td>
          <td>: Rp.</td>
          <td align="right">0</td>
        </tr>
        <tr>
          <td>50% Dari Jumlah Penghasilan</td>
          <td>: Rp.</td>
          <td align="right">0</td>
        </tr>

        <tr>
          <td>Dikurangi Penghasilan Tidak Kena Pajak (PTKP)</td>
          <td>: Rp.</td>
          <td align="right">0</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td align="right">-----------------</td>
          <td>(-)</td>
        </tr>
        <tr>
          <td>Penghasilan Kena Pajak</td>
          <td>: Rp.</td>
          <td align="right">0</td>
        </tr>
        <tr></tr>
        <tr>
          <td>Penghasilan Kena Pajak Akumulatif</td>
          <td>: Rp.</td>
          <td align="right">0</td>
        </tr>
        <tr>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td colspan="4">
            Perhitungan PPH Pasal 21 ( Dengan Tarif Pasal 17 )
          </td>
        </tr>
        <tr>
          <td>0 s/d 60.000.000 (5%)</td>
          <td>: Rp.</td>
          <td align="right">0</td>
        </tr>
        <tr>
          <td>60.000.000 s/d 250.000.000 (15%)</td>
          <td>: Rp.</td>
          <td align="right">0</td>
        </tr>
        <tr>
          <td>250.000.000 s/d 500.000.000 (25%)</td>
          <td>: Rp.</td>
          <td align="right">0</td>
        </tr>
        <tr>
          <td>500.000.000 s/d 5 miliar (30%)</td>
          <td>: Rp.</td>
          <td align="right">0</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td align="right">-----------------</td>
        </tr>
        <tr>
          <td>Jumlah Angsuran Pajak</td>
          <td>: Rp.</td>
          <td align="right">0</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td align="right">-----------------</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td align="right"></td>
        </tr>
      </tbody>
    </table>
    <br />
    Informasi Penyesuaian Besarnya Penghasilan Tidak Kena Pajak (PTKP)<br />
    Peraturan Menteri Keuangan Nomor : 122/PMK.010/2015
    <table border="1">
      <tbody>
        <tr>
          <td>PTKP Mulai Tahun Pajak 2015</td>
          <td>PTKP/Tahun</td>
          <td>PTKP/Bulan</td>
        </tr>
        <tr>
          <td>Diri WP orang pribadi</td>
          <td align="right">54.000.000</td>
          <td align="right">4.500.000</td>
        </tr>
        <tr>
          <td>Tambahan untuk WP Kawin</td>
          <td align="right">4.500.000</td>
          <td align="right">375.000</td>
        </tr>
        <tr>
          <td>
            Tambahan untuk istri yang penghasilannya digabung dengan penghasilan
            suami
          </td>
          <td align="right">54.000.000</td>
          <td align="right">4.500.000</td>
        </tr>
        <tr>
          <td>Tambahan untuk setiap tanggungan (Maksimal 3)</td>
          <td align="right">4.500.000</td>
          <td align="right">375.000</td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />
    <table>
      <tbody>
        <tr>
          <td>
            <b>Rincian Penghasilan Stockist<b></b></b>
          </td>
        </tr>
        <tr>
          <td>Rabat Stockist</td>
          <td>: Rp.</td>
          <td align="right">0</td>
        </tr>
        <tr>
          <td>Rabat Stockist Push Up</td>
          <td>: Rp.</td>
          <td align="right">0</td>
        </tr>
        <tr>
          <td>Rabat Stockist Level 1</td>
          <td>: Rp.</td>
          <td align="right">0</td>
        </tr>
        <tr>
          <td>Rabat Stockist Level 2</td>
          <td>: Rp.</td>
          <td align="right">0</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td align="right">-----------------</td>
          <td>(+)</td>
        </tr>
        <tr>
          <td>Total Rabat Stockist</td>
          <td>: Rp.</td>
          <td align="right">0</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'RincianPajak',
};
</script>

<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="my-6 rounded-lg bg-white p-4">
      <div class="flex w-full justify-end">
        <DownloadButton @click="handleDownload" />
      </div>
      <div class="border-b border-gray-200 py-5 sm:px-6">
        <RincianPajakTable />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DownloadButton from '@/components/button/DownloadButton.vue';
import RincianPajakTable from '@/components/mitra-usaha/rincian-pajak-table.vue';

export default {
  name: 'StatementKomisi',
  components: {
    DownloadButton,
    RincianPajakTable,
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({}),
    handleDownload() {
      window.print();
    },
  },
};
</script>

<template>
  <div>
    <loading v-if="loading" />
    <table
      v-else-if="
        getPeriods.data.length &&
        getOffice.data &&
        getCommision.data &&
        getMaster.data &&
        getUpline.data
      "
      class="mx-auto"
      width="700"
    >
      <img src="@/assets/images/kop.png" alt="" style="width: 500px" />
      <br />
      <tbody class="text-xs">
        <tr>
          <td colspan="7">
            <strong>STATEMENT KOMISI BULANAN</strong><br />
            Periode :
            <span>
              {{ getPeriods.data[7].attributes.name }}
            </span>
          </td>
          <td colspan="6">
            Kepada Yth,<br />
            Bpk/Ibu <b>{{ getCommision.data[0].nmadst }}</b
            ><br />
            {{ getMaster.data[0].alm1 }}<br />
            {{ getMaster.data[0].alm2 }}<br />
            {{ getMaster.data[0].kta }}
          </td>
        </tr>
        <tr></tr>
        <tr>
          <td colspan="2">
            No.Tetap<br />
            Nama Mitra Usaha<br />
            Peringkat
          </td>
          <td colspan="5">
            : {{ getMaster.data[0].kdedst }} / {{ getMaster.data[0].nmrdst
            }}<br />
            : {{ getMaster.data[0].nmadst }}<br />
            : Mitra Usaha
          </td>
          <td colspan="2">
            No Upline <br />
            Nama Upline <br />
            Peringkat
          </td>
          <td colspan="5">
            : {{ getMaster.data[0].nmrupl }}<br />
            : {{ getMaster.data[0].nmaupl }}<br />
            : {{ konversiperingkat(getMaster.data[0].jenjang) }}
          </td>
        </tr>

        <tr>
          <td colspan="7"><strong>Informasi Data Cashbill</strong></td>
          <td colspan="5"><strong>Informasi Data Komisi Bulanan</strong></td>
        </tr>
        <tr>
          <td>Tanggal</td>
          <td align="center">No. Casbill</td>
          <td colspan="3" align="center">PV</td>
          <td align="right">BV</td>
          <td></td>
          <td colspan="3">Komisi Pengembangan</td>
          <td>:</td>
          <td colspan="2" align="right">
            {{ getCommision.data[0].rbtkembang ?? 0 | toCurrency }}
          </td>
        </tr>
        <tr>
          <td></td>
          <td align="center"></td>
          <td colspan="3" align="center"></td>
          <td align="right"></td>
          <td></td>
          <td colspan="3">Komisi Karier</td>
          <td>:</td>
          <td colspan="2" align="right">
            {{ getCommision.data[0].rbtkarir ?? 0 | toCurrency }}
          </td>
        </tr>
        <tr>
          <td></td>
          <td align="center"></td>
          <td colspan="3" align="center"></td>
          <td align="right"></td>
          <td></td>
          <td colspan="3">Komisi Kepemimpinan</td>
          <td>:</td>
          <td colspan="2" align="right">
            {{ getCommision.data[0].rbtpimpin ?? 0 | toCurrency }}
          </td>
        </tr>
        <tr>
          <td></td>
          <td align="center"></td>
          <td colspan="3" align="center"></td>
          <td align="right"></td>
          <td></td>
          <td colspan="3">Komisi Break Away</td>
          <td>:</td>
          <td colspan="2" align="right">
            {{ getCommision.data[0].rbtbaway ?? 0 | toCurrency }}
          </td>
        </tr>
        <tr>
          <td></td>
          <td align="center"></td>
          <td colspan="3" align="center"></td>
          <td align="right"></td>
          <td></td>
          <td colspan="3">Komisi Diamond</td>
          <td>:</td>
          <td colspan="2" align="right">
            {{ getCommision.data[0].rbtdiamond ?? 0 | toCurrency }}
          </td>
        </tr>
        <tr>
          <td></td>
          <td align="center"></td>
          <td colspan="3" align="center"></td>
          <td align="right"></td>
          <td></td>
          <td colspan="3">Komisi Executive Diamond</td>
          <td>:</td>
          <td colspan="2" align="right">
            {{ getCommision.data[0].rbtexecutive ?? 0 | toCurrency }}
          </td>
        </tr>
        <tr>
          <td></td>
          <td align="center"></td>
          <td colspan="3" align="center"></td>
          <td align="right"></td>
          <td></td>
          <td colspan="3">Komisi Double Diamond</td>
          <td>:</td>
          <td colspan="2" align="right">
            {{ getCommision.data[0].rbtdouble ?? 0 | toCurrency }}
          </td>
        </tr>
        <tr>
          <td></td>
          <td align="center"></td>
          <td colspan="3" align="center"></td>
          <td align="right"></td>
          <td></td>
          <td colspan="3">Komisi Crown Diamond</td>
          <td>:</td>
          <td colspan="2" align="right">
            {{ getCommision.data[0].rbtcrown ?? 0 | toCurrency }}
          </td>
        </tr>
        <tr>
          <td></td>
          <td align="center"></td>
          <td colspan="3" align="center"></td>
          <td align="right"></td>
          <td></td>
          <td colspan="3">Komisi Pass Up</td>
          <td>:</td>
          <td colspan="2" align="right">
            {{ getCommision.data[0].rbtpup ?? 0 | toCurrency }}
          </td>
        </tr>
        <tr>
          <td></td>
          <td align="center"></td>
          <td colspan="3" align="center"></td>
          <td align="right"></td>
          <td></td>
          <td colspan="3"></td>
          <td></td>
          <td colspan="2" align="right"></td>
        </tr>
        <tr>
          <td colspan="7"></td>
          <td colspan="3"></td>
          <td></td>
          <td colspan="2" align="right"></td>
        </tr>
        <tr>
          <td colspan="7"></td>
          <td colspan="3"></td>
          <td></td>
          <td colspan="2" align="right"></td>
        </tr>
        <tr>
          <td colspan="7"></td>
          <td colspan="3"></td>
          <td></td>
          <td colspan="2" align="right"></td>
        </tr>
        <tr>
          <td colspan="2">Personal Omzet</td>
          <td>:</td>
          <td colspan="2" align="center">
            {{ getCommision.data[0].pvpri ?? 0 | toCurrency }}
          </td>
          <td align="right">
            {{ getCommision.data[0].bvpri ?? 0 | toCurrency }}
          </td>
          <td></td>
          <td colspan="6" align="right">-------------- +</td>
        </tr>
        <tr>
          <td colspan="2">Omzet Group Total</td>
          <td>:</td>
          <td colspan="2" align="center">
            {{ getCommision.data[0].pvgroup ?? 0 | toCurrency }}
          </td>
          <td align="right">
            {{ getCommision.data[0].bvgroup ?? 0 | toCurrency }}
          </td>
          <td></td>
          <td colspan="3">Total Komisi Bulan ini</td>
          <td>:</td>
          <td colspan="2" align="right">
            {{ getTotalRabat }}
          </td>
        </tr>
        <tr>
          <td colspan="2"></td>
          <td colspan="3" align="center">--------- +</td>
          <td align="right">--------- +</td>
          <td></td>
          <template v-if="getCommision.data[0].pajak">
            <td colspan="3">Angsuran Pajak PPh 21</td>
            <td></td>
            <td colspan="2" align="right">
              {{ getCommision.data[0].pajak }}
            </td>
          </template>
          <template>
            <td colspan="3"></td>
            <td></td>
            <td colspan="2" align="right"></td>
          </template>
        </tr>
        <tr>
          <td colspan="2">Total Omzet</td>
          <td>:</td>
          <td colspan="2" align="center">
            {{ getCommision.data[0].pvomzet }}
          </td>
          <td align="right">
            {{ getCommision.data[0].bvomzet }}
          </td>
          <td></td>
          <td colspan="3"></td>
          <td></td>
          <td colspan="2" align="right"></td>
        </tr>
        <tr>
          <td colspan="7"></td>
          <td colspan="3"></td>
          <td></td>
          <td colspan="2" align="right"></td>
        </tr>
        <tr>
          <td colspan="2">Total PV Bulan Lalu</td>
          <td>:</td>
          <td colspan="2" align="center">4,155</td>
          <td></td>
          <td></td>
          <td colspan="6" align="right">-------------- -</td>
        </tr>
        <tr>
          <td colspan="2">Total PV Akumulatif</td>
          <td>:</td>
          <td colspan="2" align="center">4,155</td>
          <td></td>
          <td></td>
          <td colspan="3">Total Komisi Diterima</td>
          <td>:</td>
          <td colspan="2" align="right">0</td>
        </tr>
        <tr>
          <td colspan="11"></td>
        </tr>
        <tr>
          <td colspan="7"><b>Rincian Mitra Usaha Langsung</b></td>
          <td colspan="2" align="center"><b>MitraUsaha</b></td>
          <td align="center"></td>
          <td colspan="4" align="center"><b>Manager s/d Crown</b></td>
        </tr>
        <tr>
          <td><b>No Tetap</b></td>
          <td colspan="2"><b>Nama Mitra Usaha</b></td>
          <td align="center"><b>Peringkat</b></td>
          <td align="right" colspan="2"><b>PV</b></td>
          <td align="right" colspan="2"><b>BV</b></td>
          <td align="center"><b>Pass Up</b></td>
          <td align="right"><b>PV</b></td>
          <td colspan="2" align="right"><b>BV</b></td>
        </tr>
        <tr v-for="upline in getUpline.data" :key="upline">
          <!-- kode -->
          <td>
            <b>{{ upline.kdedst }}</b>
          </td>
          <!-- nama mitra usaha -->
          <td colspan="2">
            <b>{{ upline.nmadst }}</b>
          </td>
          <!-- peringkat -->
          <td align="center">
            <b>{{ upline.jenjang }}</b>
          </td>
          <!-- pv -->
          <td align="right" colspan="2">
            <b>
              {{ (upline.pvlalu + upline.pvpri + upline.pvgroup) | toCurrency }}
            </b>
          </td>
          <!-- bv -->
          <td align="right" colspan="2">
            <b>{{ (upline.bvpri + upline.bvgroup) | toCurrency }}</b>
          </td>
          <!-- pass up -->
          <td align="center">
            <b>{{ upline.dipup }}</b>
          </td>
          <!-- pv -->
          <td align="right">
            <b>
              {{ (upline.pvpri + upline.pvgroup) | toCurrency }}
            </b>
          </td>
          <!-- bv -->
          <td colspan="2" align="right">
            <b>
              {{ (upline.bvpri + upline.bvgroup) | toCurrency }}
            </b>
          </td>
        </tr>

        <tr class="border-b"></tr>
        <tr>
          <td colspan="7"></td>
          <td align="right"><strong>0</strong></td>
          <td align="center"><strong>0</strong></td>
          <td align="right"><strong>0</strong></td>
          <td align="right"><strong>0</strong></td>
          <td colspan="2" align="right"><strong> 0</strong></td>
        </tr>

        <tr>
          <td colspan="11">
            Tempat Pengambilan Statement di Stockist
            <b>
              {{ getCommision.data[0].tmpbns }}
            </b>
          </td>
        </tr>

        <tr>
          <td colspan="11">
            Komisi dipending / diakumulasikan dan jika telah memenuhi syarat
            akan ditransfer ke Rekening Bank BRI Cabang KENONGO MULYO No.
            Rekening 636001000415506 Atas Nama SULISTIYOWATI
          </td>
        </tr>
        <tr></tr>
        <tr>
          <td colspan="11"></td>
        </tr>
        <tr></tr>
        <tr>
          <td colspan="11"></td>
        </tr>

        <tr>
          <td></td>
        </tr>

        <tr>
          <td colspan="7">
            <b>CHAMPIONT POINT 2023 - 2026 </b><br />
            Wisata Luar Negeri<br />
            Dan Atau Wisata Regili<br /><br />
            Poin CP Lalu &nbsp;: {{ getCommision.data[0].cplalu }}<br />
            Poin CP &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : 0
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            --- +<br />
            Total Poin CP : {{ getCommision.data[0].totalcp }}
          </td>
          <td colspan="6">
            <b>MORESKIN MORE-REWARD 2023</b><br />
            More Saving - More Holidays - More Religiously<br />
            Periode : 1 Januari s/d 31 Desember 2023<br /><br />
            Poin Lalu &nbsp;: {{ getCommision.data[0].pmslalu }}<br />
            Poin &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :
            {{ getCommision.data[0].pms }}
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            --- +<br />
            Total Poin : {{ getCommision.data[0].totalpms }}
          </td>
        </tr>
        <tr>
          <td></td>
        </tr>
        <tr>
          <td></td>
        </tr>
        <tr>
          <td colspan="15">
            <u><b>Informasi :</b></u>
          </td>
        </tr>
        <tr>
          <td colspan="15">
            <b
              >SOSIALISASI PERATURAN MENTERI PERDAGANGAN TENTANG LARANGAN BAGI
              MITRA USAHA BERJUALAN DI MARKETPLACE DAN PERATURAN PERDAGANGAN
              MELALUI SISTEM ELEKTRONIK :
              <a href="https://member.naturalnusantara.co.id/fileinfo.php">
                KLIK DISINI</a
              ></b
            >
          </td>
        </tr>
        <tr>
          <td colspan="15">
            <br />
            PROGRAM FUNTASTRIP ( FUN TO ANNIVERSARY SPIRIT TRIP ) BERUPA REWARD
            WISATA LUAR NEGERI ATAU EMAS BATANGAN<br />
            PERIODE JULI S/D DESEMBER 2022 TELAH BERAKHIR PADA PERIODE STATEMENT
            RABAT BULAN DESEMBER 2022 INI.<br />
            SILAHKAN CEK TOTAL POIN REWARD ANDA PADA MASING-MASING STATEMENT
            RABAT DI PERIODE DESEMBER 2022.<br />
            INFORMASI LEBIH LANJUT MENGENAI PEMENANG REWARD FUNTASTRIP INI
            MENUNGGU SURAT RESMI DARI PERUSAHAAN<br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </td>
        </tr>
        <tr></tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { StorageService } from '@/services/storage.service';
import ApiService from '@/services/api.service';

export default {
  name: 'TableStatementKomisi',
  computed: {
    ...mapGetters({
      getOffice: 'offices/getOffice',
      getPeriods: 'periods/getPeriods',
    }),
    getTotalRabat() {
      if (!this.getMaster.data || !this.getCommision.data) {
        return 0;
      }
      const sums = [
        'rbtkembang',
        'rbtkarir',
        'rbtpimpin',
        'rbtbaway',
        'rbtdiamond',
        'rbtexecuti',
        'rbtdouble',
        'rbtcrown',
        'rbtpromo',
        'rbtpup',
        'rbtbos',
        'rbtroyalti',
      ];

      const substracts = ['bpud'];

      let total = 0;

      for (const iterator of sums) {
        console.log(this.getCommision.data[0][iterator]);
        total += this.getCommision.data[0][iterator] ?? 0;
      }

      for (const iterator of substracts) {
        console.log(this.getCommision.data[0][iterator]);
        total -= this.getCommision.data[0][iterator] ?? 0;
      }

      return total;
    },
  },
  data() {
    return {
      me: StorageService.getUser(),
      getMaster: {},
      getCommision: {},
      getUpline: {},
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      fetchOfficeById: 'offices/fetchOfficeById',
      fetchPeriods: 'periods/fetchPeriods',
    }),
    handleDownload() {
      window.print();
    },
    konversiperingkat($kode) {
      let $kodeperingkat = $kode;
      let $namaperingkat = '';

      switch ($kodeperingkat) {
        case 'D':
          $namaperingkat = 'Mitra Usaha';
          break;
        case 'M':
          $namaperingkat = 'Manager';
          break;
        case 'SM':
          $namaperingkat = 'Silver Manager';
          break;
        case 'GM':
          $namaperingkat = 'Gold Manager';
          break;
        case 'PM':
          $namaperingkat = 'Pearl Manager';
          break;
        case 'EM':
          $namaperingkat = 'Emerald Manager';
          break;
        case 'DD':
          $namaperingkat = 'Diamond Director';
          break;
        case 'EDD':
          $namaperingkat = 'Executive Diamond Director';
          break;
        case 'DDD':
          $namaperingkat = 'Double Diamond Director';
          break;
        case 'CDD':
          $namaperingkat = 'Crown Diamond Director';
          break;
      }
      return $namaperingkat;
    },
  },
  async created() {
    this.loading = true;
    this.fetchOfficeById({
      office_id: StorageService.getUser().office_id,
      params: {
        include: 'uplines',
      },
    }).then(() => {});
    this.fetchPeriods({
      'filter[year]': 2023,
      // "filter[search]": "Agustus"
    });
    let getMaster = ApiService.get(`api/v1/offices/-actions/master`);
    let getStatementCommision = ApiService.get(
      `api/v1/offices/-actions/statementCommision`
    );
    let getUpline = ApiService.get(
      `api/v1/offices/-actions/statementCommision?upline=1`
    );

    Promise.all([getMaster, getStatementCommision, getUpline])
      .then((response) => {
        const [master, commision, upline] = response;
        this.getMaster = master.data;
        this.getCommision = commision.data;
        this.getUpline = upline.data;

        this.loading = false;
      })
      .catch((err) => {
        console.error(err);
        this.loading = false;
      });
  },
};
</script>
